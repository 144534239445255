import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

//
// TODO: this might fix the flex issue (this logo can't currently be contained in a flex container)
// https://www.gatsbyjs.org/packages/gatsby-image/#avoiding-stretched-images-using-the-fluid-type
//

const GreenhillLogo = ({className}) => {
    const data = useStaticQuery(graphql`
      query {
        image: file(relativePath: { eq: "Greenhill-MASTER-Colour.png" }) {
          childImageSharp {
            fluid(maxWidth: 680, maxHeight: 340) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
  
    return <Img fluid={data.image.childImageSharp.fluid} alt="Greenhill Psychology logo" className={className} />
  }

  export default GreenhillLogo