import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import GreenhillLogo from "../components/greenhill-logo"
import GreenhillClosed from "../components/greenhill-closed"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout location="home">
    <SEO title="Warm, professional, caring psychology service serving Lancaster and Morecambe areas" />
    <div className="mx-auto max-w-3xl rounded bg-white border-primary border-4 p-4 shadow-lg">
      <GreenhillLogo />
      <div className="mx-auto prose lg:prose-lg">
        <div>
          <div class="flex justify-center">
            <img style={{margin: 0}} src="/Closed.png" alt="Greenhill Psychology now closed image" />
          </div>
          <h2 className="text-center">
          Greenhill Psychology is now closed to new clients, with a view to closing permanently
          </h2>          
          <p className="text-center">Thank you for all your support and, to previous clients, <span class="font-bold">thank you</span> for affording me the privilege of accompanying you on part of your journey of change.</p>
          <p className="text-center text-primary font-bold">With warm regards, Beverley</p>
        </div>          
      </div>
    </div>
  </Layout>
)

export default IndexPage
